import axios from "axios";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import CountUp from "react-countup";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import Layout from "../components/layout";
import Logos from "../components/logos";
import PortfolioSection from "../components/portfolio-section";
import ReviewSection from "../components/review-section";
import TeamSection from "../components/team-section";
import useOnScreen from "../components/use-on-screen";
import parse from "html-react-parser";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			wpSeoPage(title: { eq: "Home" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			wpHomePage {
				homePageFields {
					backgroundImage {
						altText
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					bookAMeetingSection {
						title
						description
					}
					growSection {
						title
						smallTitle
						description
						button1 {
							link
							text
						}
					}
					heroSection {
						title
						description
						button2 {
							text
							link
						}
						button1 {
							text
							link
						}
					}
					howRjmCanHelpSection {
						button1 {
							link
							text
						}
						title
						description
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					portfolioSection {
						title
						description
					}
					sustainabilitySection {
						title
						description
						button1 {
							text
							link
						}
					}
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}

			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			webDevimg: wpMediaItem(title: { eq: "web-design-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg2: wpMediaItem(title: { eq: "side-image-2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			bgImg: wpMediaItem(title: { eq: "home-background-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg1: wpMediaItem(title: { eq: "shopify" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			logoImg3: wpMediaItem(title: { eq: "Gatsby-Logo" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg4: wpMediaItem(title: { eq: "Firebase" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg5: wpMediaItem(title: { eq: "Semrush" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg6: wpMediaItem(title: { eq: "ECO_IMAGE_1" }) {
				altText

				mediaDetails {
					height
					width
				}

				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg7: wpMediaItem(title: { eq: "ECO_IMAGE_2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg8: wpMediaItem(title: { eq: "ECO_IMAGE_3" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			logoImg9: wpMediaItem(title: { eq: "ECO_IMAGE_4" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			seoImg: wpMediaItem(title: { eq: "seo-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			gAdsImg: wpMediaItem(title: { eq: "google-ads-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			ecommerceImg: wpMediaItem(title: { eq: "e-commerce-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const [trees, setTrees] = useState("");
	const [carbon, setCarbon] = useState("");
	const [months, setMonths] = useState("");
	const [selectedImage, setSelectedImage] = useState(data.webDevimg);

	function monthDiff(dateFrom, dateTo) {
		return (
			dateTo.getMonth() -
			dateFrom.getMonth() +
			12 * (dateTo.getFullYear() - dateFrom.getFullYear())
		);
	}

	useEffect(() => {
		axios
			.get(`https://public.ecologi.com/users/rjmdigital/impact`)
			.then((res) => {
				setTrees(res.data.trees);
				setCarbon(res.data.carbonOffset);
				setMonths(monthDiff(new Date(2021, 10), new Date()));
			});
	}, []);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const ref1 = useRef();
	const onScreen1 = useOnScreen(ref1, "0px");

	const {
		wpHomePage: {
			homePageFields: {
				backgroundImage,
				bookAMeetingSection,
				growSection,
				heroSection,
				howRjmCanHelpSection,
				portfolioSection,
				sustainabilitySection,
			},
		},
	} = data;

	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
				},
			},
		],
	};

	const enterAnimation = (text, img) => {
		if (selectedImage !== img) {
			gsap.to(
				".selected-image",

				{ opacity: 0, duration: 0.5 }
			);
			gsap.to(
				".selected-image",

				{ opacity: 1, duration: 1, delay: 0.6 }
			);
			setTimeout(function () {
				setSelectedImage(img);
			}, 500);
		}

		gsap.to(
			text,

			{ opacity: 1, color: "#FFCA2C", duration: 0.5 }
		);
	};
	const exitAnimation = (text) => {
		gsap.to(
			text,

			{ opacity: 0.69, color: "white", duration: 0.5 }
		);
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>

			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						customTitle
						button1="primary-link-dark"
						button1Text={heroSection.button1.text}
						button1Link={heroSection.button1.link}
						button1Var="white"
						button2="white-link-yellow"
						button2Text={heroSection.button2.text}
						button2Link={heroSection.button2.link}
						button2Var="primary"
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={heroSection.title}
						description={heroSection.description}
					/>
				</section>
				<section className="bg-light-grey pt-5 pt-lg-8">
					<Container>
						<Row className="justify-content-center">
							<Col lg={8} xl={6} className="text-center ">
								<p className="ssp-bold text-uppercase text-light-blue">
									{growSection.smallTitle}
								</p>
								<div>{parse(growSection.title)}</div>

								<div>{parse(growSection.description)}</div>
								<Button
									className="mt-5 cta-btn fs-5 w-100 w-md-auto py-2 px-4 white-link"
									variant="primary"
									as={Link}
									to={growSection.button1.link}
								>
									{growSection.button1.text}
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				<Logos noTitle />
				<section
					className="py-5 py-lg-7 "
					style={{
						background:
							"transparent linear-gradient(52deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
				>
					<Container>
						<Row className="align-items-center">
							<Col className="text-center d-lg-none" lg={6}>
								<Link
									to="/web-design"
									className="display-3 ssp-bold d-block text-decoration-none mb-4 mb-lg-6 white-link grow-hover-1"
								>
									WEB DESIGN
								</Link>

								<Link
									to="/seo"
									className="display-3 mb-4 mb-lg-6 ssp-bold d-block text-decoration-none white-link grow-hover-3"
								>
									SEO
								</Link>
								<Link
									to="/google-ads"
									className="display-3 mb-4 mb-lg-6 ssp-bold d-block text-decoration-none white-link grow-hover-4"
								>
									GOOGLE ADS
								</Link>
								<Link
									to="/e-commerce-websites"
									className="display-3 mb-4 mb-lg-6 ssp-bold d-block text-decoration-none white-link grow-hover-5"
								>
									E-COMMERCE
								</Link>
							</Col>
							<Col className="text-start d-none d-lg-block" lg={6}>
								<Link
									to="/web-design"
									onMouseEnter={() =>
										enterAnimation(".grow-hover-1", data.webDevimg)
									}
									onMouseLeave={() => exitAnimation(".grow-hover-1")}
									className="display-3 ssp-bold d-block text-decoration-none mb-4 mb-lg-6 grow-hover-1"
								>
									WEB DESIGN
								</Link>

								<Link
									to="/seo"
									onMouseEnter={() =>
										enterAnimation(".grow-hover-3", data.seoImg)
									}
									onMouseLeave={() => exitAnimation(".grow-hover-3")}
									className="display-3 mb-4 mb-lg-6 ssp-bold d-block text-decoration-none grow-hover-3"
								>
									SEO
								</Link>
								<Link
									to="/google-ads"
									onMouseEnter={() =>
										enterAnimation(".grow-hover-4", data.gAdsImg)
									}
									onMouseLeave={() => exitAnimation(".grow-hover-4")}
									className="display-3 mb-4 mb-lg-6 ssp-bold d-block text-decoration-none grow-hover-4"
								>
									GOOGLE ADS
								</Link>
								<Link
									to="/e-commerce-websites"
									onMouseEnter={() =>
										enterAnimation(".grow-hover-5", data.ecommerceImg)
									}
									onMouseLeave={() => exitAnimation(".grow-hover-5")}
									className="display-3 mb-4 mb-lg-6 ssp-bold d-block text-decoration-none grow-hover-5"
								>
									E-COMMERCE
								</Link>
							</Col>
							<Col className="d-none d-lg-block">
								<div
									className="   w-100 "
									style={{
										overflow: "hidden",
									}}
								>
									<GatsbyImage
										image={selectedImage.gatsbyImage}
										alt={selectedImage.altText}
										className="w-90 selected-image"
										objectPosition="top center"
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				<section className="py-5 py-lg-7 bg-light-grey">
					<PortfolioSection
						title={parse(portfolioSection.title)}
						description={portfolioSection.description}
						page="Home"
					/>
				</section>
				<section className="pt-5 pb-md-5 py-lg-7 pb-md-5 bg-light-grey">
					<ReviewSection page="Home" />
				</section>
				<section className="vh-100 d-none d-xl-block ">
					<GatsbyImage
						className="vh-100 position-fixed w-100"
						style={{ zIndex: "-9999", top: "0" }}
						image={backgroundImage.gatsbyImage}
						alt=""
					/>
				</section>
				<section className="pt-5 pt-lg-7 bg-light-grey">
					<Container>
						<Row className="justify-content-between align-items-lg-start ">
							<Col className=" pt-5 pb-5 pb-lg-9 mb-xl-5" lg={6}>
								{/* <div
									className=" d-lg-none w-100 mb-5 "
									style={{
										borderRadius: "15px",
										overflow: "hidden",

										boxShadow: "0px 3px 70px #00000086",
									}}
								>
									<GatsbyImage
										image={
											data.sideImg1.gatsbyImage
										}
										alt=""
									/>
								</div> */}
								<div>{parse(howRjmCanHelpSection.title)}</div>

								<div>{parse(howRjmCanHelpSection.description)}</div>

								<Button
									className=" cta-btn w-100 w-md-auto fs-5 white-link-yellow py-2 px-4 mt-4 "
									variant="primary"
									as={Link}
									to={howRjmCanHelpSection.button1.link}
								>
									{howRjmCanHelpSection.button1.text}
								</Button>
							</Col>
							<Col className="position-relative pt-5" xl={4} lg={5}>
								{/* <div
									className=" d-none d-lg-block d-xl-none w-100 mb-5 "
									style={{
										borderRadius: "15px",
										overflow: "hidden",

										boxShadow: "0px 3px 70px #00000086",
									}}
								>
									<GatsbyImage
										image={
											data.sideImg1.gatsbyImage
										}
										alt=""
									/>
								</div> */}
								<div
									className="  d-none d-lg-block  w-100 mb-5 "
									style={{
										borderRadius: "15px",
										overflow: "hidden",

										boxShadow: "0px 3px 70px #00000086",
									}}
								>
									<GatsbyImage
										image={howRjmCanHelpSection.image.gatsbyImage}
										alt=""
										className="w-100"
									/>
								</div>
								{/* <div
									className=" d-none d-xl-block w-100  end-0 position-absolute "
									style={{
										borderRadius: "10px",
										overflow: "hidden",
										top: "-1.5rem",
										boxShadow: "0px 3px 90px #00000043",
									}}
								>
									<GatsbyImage
										image={
											data.sideImg1.gatsbyImage
										}
										alt=""
									/>
								</div> */}

								{/* <div
									className=" d-none d-xl-block position-absolute w-100 "
									style={{
										borderRadius: "10px",
										overflow: "hidden",
										left: "-7rem",
										top: "13rem",
										boxShadow: "0px 3px 90px #00000043",
									}}
								>
									<GatsbyImage
										image={
											data.sideImg2.gatsbyImage
										}
										alt=""
									/>
								</div> */}
							</Col>
						</Row>
					</Container>
				</section>
				<section className="pb-5 pb-lg-7  bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<div>{parse(bookAMeetingSection.title)}</div>
								<p>{bookAMeetingSection.description}</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<TeamSection />
				</section>
				<section
					style={{
						background:
							"transparent linear-gradient(180deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
					className="py-5 py-lg-7"
				>
					<Container>
						<Row className="justify-content-center">
							<Col xs={6} lg={true}>
								<GatsbyImage
									image={data.logoImg1.gatsbyImage}
									alt={data.logoImg1.altText}
									className="small-max-width"
								/>
							</Col>
							<Col xs={6} className="d-flex align-items-center" lg={true}>
								<StaticImage
									src="../images/Wordpress-Final.png"
									placeholder="blurred"
									quality={100}
									className="small-max-width"
								/>
							</Col>
							<Col xs={6} lg={true}>
								<GatsbyImage
									image={data.logoImg3.gatsbyImage}
									alt={data.logoImg3.altText}
									className="small-max-width"
								/>
							</Col>
							<Col xs={6} lg={true}>
								<GatsbyImage
									image={data.logoImg4.gatsbyImage}
									alt={data.logoImg4.altText}
									className="small-max-width"
								/>
							</Col>
							<Col xs={6} lg={true}>
								<GatsbyImage
									image={data.logoImg5.gatsbyImage}
									alt={data.logoImg5.altText}
									className="small-max-width"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<section
					style={{ overflow: "hidden" }}
					className="py-5 pb-lg-7 pt-lg-9 position-relative bg-dark-bg "
				>
					<div
						style={{ height: "1rem", backgroundColor: "#27676A", zIndex: 2 }}
						className="w-100 position-absolute bottom-0"
					></div>
					<StaticImage
						className=" start-0 position-absolute"
						style={{
							zIndex: 1,
							width: "100%",
							maxHeight: "100%",
							bottom: "0.5rem",
						}}
						src="../images/Sustainble-background.svg"
						placeholder="blurred"
						quality={100}
					/>
					<Container style={{ zIndex: 3 }} className="position-relative">
						<Row className="justify-content-center">
							<Col lg={8} xl={7} className="text-center pb-5">
								{" "}
								<div>{parse(sustainabilitySection.title)}</div>
								<div>{parse(sustainabilitySection.description)}</div>
								<Button
									className=" cta-btn w-100 w-md-auto fs-5 primary-link-dark py-2 px-4 "
									variant="white"
									as={Link}
									to={sustainabilitySection.button1.link}
								>
									{sustainabilitySection.button1.text}
								</Button>
							</Col>
						</Row>
						<Row>
							<Col>
								<h3 className="display-5 pb-5 text-white">Our partnerships</h3>
							</Col>
						</Row>
						<Row className="gy-5">
							<Col className="text-center" xs={6} md={3}>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://ecofriendlyweb.org/client/rjm-digital/"
								>
									<div
										style={{
											borderRadius: "30px",
											overflow: "hidden",
											width: "110px",
											height: "110px",
										}}
										className="bg-white d-flex justify-content-center mx-auto align-items-center"
									>
										<GatsbyImage
											style={{ maxWidth: "90px" }}
											image={data.logoImg6.gatsbyImage}
											alt={data.logoImg6.altText}
										/>
									</div>
								</a>
							</Col>
							<Col className="text-center" xs={6} md={3}>
								<div
									style={{
										borderRadius: "30px",
										overflow: "hidden",
										width: "110px",
										height: "110px",
									}}
									className="bg-white d-flex justify-content-center mx-auto align-items-center"
								>
									<GatsbyImage
										style={{ maxWidth: "90px" }}
										image={data.logoImg7.gatsbyImage}
										alt={data.logoImg7.altText}
									/>
								</div>
							</Col>
							<Col className="text-center" xs={6} md={3}>
								<div
									style={{
										borderRadius: "30px",
										overflow: "hidden",
										width: "110px",
										height: "110px",
									}}
									className="bg-white d-flex justify-content-center mx-auto align-items-center"
								>
									<GatsbyImage
										style={{ maxWidth: "90px" }}
										image={data.logoImg8.gatsbyImage}
										alt={data.logoImg8.altText}
									/>
								</div>
							</Col>
							<Col className="text-center" xs={6} md={3}>
								<div
									style={{
										borderRadius: "30px",
										overflow: "hidden",
										width: "110px",
										height: "110px",
									}}
									className="bg-white d-flex justify-content-center mx-auto align-items-center"
								>
									<GatsbyImage
										style={{ maxWidth: "90px" }}
										image={data.logoImg9.gatsbyImage}
										alt={data.logoImg9.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section
					style={{
						background:
							"transparent linear-gradient(180deg, #27676A 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
					className="py-5  py-lg-7 pb-lg-9"
				>
					<Container>
						<Row>
							<Col>
								<h2 className="display-5 pb-5 text-white">Our impact</h2>
							</Col>
						</Row>
						<Row className="d-none d-md-flex" ref={ref1}>
							<Col className="text-center" xs={6} lg={4}>
								<div
									style={{ height: "190px" }}
									className="d-flex align-items-center justify-content-center"
								>
									<StaticImage
										src="../images/ECO 1 - SVG.svg"
										placeholder="blurred"
										style={{ maxWidth: "200px" }}
										quality={100}
										className="mb-4"
									/>
								</div>
								<h3 className="text-white fs-1">
									{onScreen1 && <CountUp end={months} />} months
								</h3>
								<p className="text-white">Climate impact</p>
							</Col>
							<Col className="text-center pb-5 pb-lg-0" xs={6} lg={4}>
								<div
									style={{ height: "190px" }}
									className="d-flex align-items-center justify-content-center"
								>
									<StaticImage
										src="../images/ECO 2 - svg.svg"
										placeholder="blurred"
										style={{ maxWidth: "200px" }}
										quality={100}
										className="mb-4"
									/>
								</div>
								<h3 className="text-white fs-1">
									{onScreen1 && <CountUp decimals={2} end={carbon} />}t
								</h3>
								<p className="text-white">Carbon reduction</p>
							</Col>
							<Col className="text-center" lg={4}>
								<div
									style={{ height: "190px" }}
									className="d-flex align-items-center justify-content-center"
								>
									<StaticImage
										src="../images/ECO 3 - svg.svg"
										placeholder="blurred"
										style={{ maxWidth: "200px" }}
										quality={100}
										className="mb-4"
									/>
								</div>
								<h3 className="text-white fs-1">
									{onScreen1 && <CountUp end={trees} />}
								</h3>
								<p className="text-white">Trees in our forest</p>
							</Col>
						</Row>
						<Row className="d-md-none">
							<Col>
								<Carousel interval={3000}>
									<Carousel.Item>
										<div className="pb-5">
											<div className="text-center" style={{ height: "200px" }}>
												<StaticImage
													src="../images/ECO 1 - SVG.svg"
													placeholder="blurred"
													style={{ maxWidth: "200px" }}
													quality={100}
													className="mb-4"
												/>
											</div>
											<div className="text-center">
												<h3 className="text-white fs-1">{months} months</h3>
												<p className="text-white">Climate impact</p>
											</div>
										</div>
									</Carousel.Item>
									<Carousel.Item>
										<div className="pb-5 text-center">
											<div className="text-center" style={{ height: "200px" }}>
												<StaticImage
													src="../images/ECO 2 - svg.svg"
													placeholder="blurred"
													style={{ maxWidth: "200px" }}
													quality={100}
													className="mb-4"
												/>
											</div>
											<h3 className="text-white fs-1">{carbon}t</h3>
											<p className="text-white">Carbon reduction</p>
										</div>
									</Carousel.Item>
									<Carousel.Item>
										<div className="pb-5 text-center">
											<div className="text-center" style={{ height: "200px" }}>
												<StaticImage
													src="../images/ECO 3 - svg.svg"
													placeholder="blurred"
													style={{ maxWidth: "200px" }}
													quality={100}
													className="mb-4"
												/>
											</div>
											<h3 className="text-white fs-1">{trees}</h3>
											<p className="text-white">Trees in our forest</p>
										</div>
									</Carousel.Item>
								</Carousel>
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default IndexPage;
